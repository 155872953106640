@import "../node_modules/bootstrap-icons/font/bootstrap-icons.css"; /* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: "Poppins-Medium";
    src: url("~src/assets/fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url("~src/assets/fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
    font-family: "Poppins-Regular";
    src: url("~src/assets/fonts/Poppins/Poppins-Regular.ttf");
}

html, body {
    overflow-x: hidden;
}
